import request from '@/api/request'
import { axiosWrapper } from '@/hooks/api/axiosWrapper'
import { IQueryClientId } from '@/utils/types'
import axios from 'axios'
import { generateUUID } from 'pubnub'
import toast from 'react-hot-toast'

const urls = {
    dispatchUrl: (sourceId: string) => `/ers/v1/clients/${sourceId}/cases?`,
    videoCallEventSend: (orderId: string) => `/ers/v1/events/cases/${orderId}`,
    fleetLocationPing: (vehicleNo: string) => `/data/fleets/${vehicleNo}/location`,
}

// `https://staging-red-keeper.command.redREDOS/cases?startDate=1721722039000&endDate=1721732839000`,

export const fetchOrdersForClientId = async ({ clientId, query }: IQueryClientId) => {
    const url = `${urls.dispatchUrl(clientId)}${query}`
    const response = await axiosWrapper.get(url)
    return response.data
}

// export const fetchOrders = createAsyncThunk<any[]>(
//   'user/fetchUser',
//   async () => {
//     const response =
//     return response.data
//   }
// )

export const fetchFleetLocationPing = async (vehicleNo: string) => {
    const jwtToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0TG9naW5BdCI6IlRodSwgMTAgT2N0IDIwMjQgMDU6MjI6NTYgR01UIiwicm9sZXMiOlsiYWRtaW4iLCJkZXZlbG9wZXIiLCJhZG1pbiJdLCJuYW1lIjoidmlwdWwgc2luZ2hhbCIsImVtYWlsIjoidmlwdWwuc2luZ2hhbEByZWQuaGVhbHRoIiwiaWF0IjoxNzI5NTg5MTc3fQ.Uqp5DxGXeSYL7Biu_X7pHN0eASBNcqxw9u2H4rwSOMA'
    const axiosInstance = axios.create({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,
          }
        })
      const response =  await axiosInstance.get(`https://redos.command.red/data/fleets/${vehicleNo}/location`);
      return response.data;
  }

export const setEntityIdKeyOnRedis = async (entityId: string, expiry: number) => {
    const jwtToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0TG9naW5BdCI6IlRodSwgMTAgT2N0IDIwMjQgMDU6MjI6NTYgR01UIiwicm9sZXMiOlsiYWRtaW4iLCJkZXZlbG9wZXIiLCJhZG1pbiJdLCJuYW1lIjoidmlwdWwgc2luZ2hhbCIsImVtYWlsIjoidmlwdWwuc2luZ2hhbEByZWQuaGVhbHRoIiwiaWF0IjoxNzI5NTg5MTc3fQ.Uqp5DxGXeSYL7Biu_X7pHN0eASBNcqxw9u2H4rwSOMA'
    const axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`,
      }
    })
  
    const response = await axiosInstance.patch(`https://redos.command.red/geo/loc/${entityId}/enablePubnub?expiry=${expiry}`)
    return response.data;
  }


export const sendVideoCallEvent = async (orderId: string, userInfo: { userId: string; email: string; role: string; callType: 'audio' | 'video' }) => {
    const _payload = {
        eventId: generateUUID(),
        eventType: 'VIDEO',
        eventName: 'VIDEO::CALL_JOINED',
        eventMessage: `Joining call for case ${orderId}`,
        eventVisibility: true,
        timestamp: new Date().getTime(),
        payload: {
            platform: 'er-portal',
        },
    }

    if (userInfo) {
        _payload.payload = {
            ..._payload.payload,
            ...userInfo,
        }
    }

    const url = urls.videoCallEventSend(orderId)
    const response = request('POST', url, _payload)

    toast.promise(
        response,
        {
            loading: 'Calling Paramedic....',
            success: `Call initiated  with the Paramedic!`,
            error: 'Call fails with the Paramedic!',
        },
        {
            duration: 4000,
        }
    )

    return await response
}
